$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.church-connections-container {
    .time-warning {
        width: 60%;
        font-size: 1.2rem;

        @include breakpoint(medium) {
            width: 100%;
        }
    }

    .providers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        @include breakpoint(medium) {
            flex-direction: column;
        }

        .provider {
            flex: 0 0 auto;
            margin-bottom: 12px;
            margin-right: 12px;

            @include breakpoint(medium) {
                width: 94%;
                flex: 0 0 100%;
            }

            .header {
                display: flex;

                img {
                    width: 45px;
                    height: 45px;
                    object-fit: contain;
                }

                div {
                    margin-left: 12px;
                    margin-top: 2px;

                    h1,
                    h2 {
                        margin: 0;
                        font-weight: 550;
                    }

                    h1 {
                        font-size: 1.25rem;
                    }

                    h2 {
                        font-size: 1rem;

                        color: #ff0000;

                        @media (prefers-color-scheme: dark) {
                            color: #ff0000;
                        }

                        &.active {
                            color: #00b800;

                            @media (prefers-color-scheme: dark) {
                                color: #29e429;
                            }
                        }
                    }
                }
            }

            .actions {
                margin-top: 8px;

                a {
                    margin-right: 10px;
                }
            }
        }
    }
}
