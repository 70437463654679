$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.page-header {
  margin: 16px 0;

  .back {
    font-size: 1.3rem;
    font-weight: 600;
    text-decoration: none;

    .icon {
      width: 1.3rem;
      height: 1.3rem;
      vertical-align: middle;
      padding-bottom: 4px;
      margin-right: 4px;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .header-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 650;
  }
  
  .header-subtitle {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 500;
    color: #6d6d6d;

    @media (prefers-color-scheme: dark) {
      color: #b0b0b0;
    }
  }

  .header-links {
    display: inline-block;
    margin-top: 4px;
    font-size: 1rem;

    a {
      margin-right: 12px;
      font-weight: 650;
      text-decoration: none;

      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }
}