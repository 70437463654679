$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.week-data-container {
  .select-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include breakpoint(medium) {
      grid-template-columns: 1fr;
    }

    div {
      padding-right: 12px;

      label {
        font-size: 14px;
        font-weight: 500;
      }

      select {
        width: 100%;
      }
    }
  }

  .card-holder {
    margin-top: 12px;
    margin-bottom: 12px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
    column-gap: 10px;

    @include breakpoint(large) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(medium) {
      grid-template-columns: 1fr;
    }

    .card {
      & > * {
        margin: 0;
      }

      .status-bar {
        width: 100%;
        height: 4px;
        border-radius: 10px;
        margin-bottom: 16px;
        margin-top: 4px;
      }

      $live-color: rgb(42, 236, 42);
      $pending-color: orange;
      $inactive-color: red;

      .status-bar.live {
        background-color: $live-color;
      }

      .status-bar.pending {
        background-color: $pending-color;
      }

      .status-bar.inactive {
        background-color: $inactive-color;
      }

      h3 {
        font-size: 1rem;
        font-weight: 550;
        color: rgb(150, 149, 149);
      }

      h1 {
        font-size: 1.5rem;
      }

      .status {
        font-weight: 600;
      }

      .status.live { color: $live-color }
      .status.pending { color: $pending-color }
      .status.inactive { color: $inactive-color }

      .secondary {
        color: rgb(150, 149, 149);
        margin-bottom: 12px;
      }

      p > span {
        font-style: italic; 
      }

      span {
        a {
          margin-right: 8px;
        }
      }
    }
  }

  table {
    width: 95%;
    margin-top: 12px;
    border-collapse: collapse;

    td,
    th {
      border: 1px solid #ddd;
      padding: 8px;

      @media (prefers-color-scheme: dark) {
        border: 1px solid #545454;
      }
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;

      @media (prefers-color-scheme: dark) {
        background-color: rgba($color: #000000, $alpha: 0);
      }
    }

    tr:hover {
      background-color: #ddd;

      @media (prefers-color-scheme: dark) {
        background-color: rgb(45, 45, 45);
      }
    }

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: $tint-color;
      color: white;
    }

    td {
      a {
        margin-right: 12px;
      }
    }

    tr .roles {
      @include breakpoint(large) {
        display: flex;
        flex-direction: column;

        font-size: 10px;
      }
    }
  }
}