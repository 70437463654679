$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
#team-member-modal {
  .separator {
    width: 100%;
    height: 1px;
    background-color: rgb(204, 204, 204);
    margin-bottom: 12px;
  }

  input[type="text"], input[type="email"] {
    display: block;
    height: 20px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 13px;
    border: 1px solid #d6d6d6;
    padding: 5px 8px;
    width: 90%;

    @include breakpoint(medium) {
      width: 94%;
    }
  }

  .field-container {
    label {
      font-size: 14px;
      font-weight: 600;
    }

    input {
      margin-top: 4px;
    }
  }

  .info-holder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    grid-template-rows: 65px;

    @include breakpoint(medium) {
      grid-template-columns: 1fr;
    }
  }

  .info-holder, .roles-holder {
    margin-bottom: 12px;
  }

  .roles-holder {
    .checkboxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 12px;

      @include breakpoint(medium) {
        grid-template-columns: 1fr;
      }
    }

    a {
      font-size: 13px;
      text-decoration: none;
    }

    section {
      flex: 0 0 auto;
      width: 200px;

      input[type="checkbox"] {
        margin-right: 8px;
      }
      
      div {
        display: inline;
        font-size: 14px;

        @include breakpoint(medium) {
          font-size: 15px;
        }
      }
    }
  }
}