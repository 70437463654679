$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.modal {
  max-height: 700px;
  margin-top: 100px;
  
  .content-holder { 
    margin: 0 16px;
  }

  .header {
    padding-top: 12px;

    h3 {
      margin: 12px 0;
    }
  }

  footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    grid-template-rows: 45px;

    padding-bottom: 12px;

    @include breakpoint(medium) {
      grid-template-columns: 1fr;
    }

    button {
      flex: 1 1 100%;
      height: 40px;
      border: none;
      border-radius: 6px;
      font-size: 0.9rem;
      font-weight: 600;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.button-outline {
        border: 1px solid rgb(158, 158, 158);
        background-color: transparent;
        color: black;

        @media (prefers-color-scheme: dark) {
          color: white;
        }
      }

      &.button-filled {
        background-color: $tint-color;
        color: white;
      }
    }
  }
}