$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.checkin-hub {
  margin: 12px 8px;

  .header-title {
    margin-bottom: 0;
  }

  .header-subtitle {
    margin: 0;
    font-size: 1.7rem;
    font-weight: 600;
    color: #6d6d6d;

    @media (prefers-color-scheme: dark) {
      color: #b0b0b0;
    }
  }

  .stats-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      flex-direction: column;
    }

    .stat-card {
      flex: 0 0 30%;
      margin-bottom: 12px;
      margin-right: 12px;

      @include breakpoint(large) {
        flex: 0 0 40%;
      }

      @include breakpoint(medium) {
        flex: 0 0 100%;
      }
    }
  }

  .region-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;

    @include breakpoint(medium) {
      flex-direction: column;
    }

    .card {
      flex: 0 0 30%;
      margin-bottom: 12px;
      margin-right: 12px;

      @include breakpoint(large) {
        flex: 0 0 40%;
      }

      @include breakpoint(medium) {
        flex: 0 0 100%;
      }

      .title {
        font-size: 1.2rem;
        margin: 0;
        margin-bottom: 2px;
      }

      .description {
        font-size: 1rem;
        margin: 0;
        color: #898989;
        font-weight: 550;
        margin-bottom: 12px;
      }

      h5 {
        &:not(.description) {
          font-size: 1rem;
          margin: 0;
          margin-bottom: 6px;
          font-weight: 600;

          .tooltip {
            position: relative;
            display: inline-block;

            .tooltip-content {
              visibility: hidden;
              width: 200px;
              background-color: white;
              color: black;
              box-shadow: $card-shadow;
              text-align: left;
              border-radius: 12px;
              padding: 12px;
              position: absolute;
              z-index: 1;
              bottom: 150%;
              left: 50%;
              margin-left: -60px;

              @media (prefers-color-scheme: dark) {
                background-color: rgb(104, 104, 104);
                color: white;
              }

              h3 {
                margin: 0;
                font-weight: 550;
              }

              p {
                margin-top: 4px;
                margin-bottom: 4px;

                color: #6F6F6F;
                font-weight: 400;

                @media (prefers-color-scheme: dark) {
                  color: rgb(213, 213, 213)
                }
              }

              &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 28%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: white transparent transparent transparent;

                @media (prefers-color-scheme: dark) {
                  border-color: rgb(104, 104, 104) transparent transparent transparent;
                }
              }
            }

            &:hover {
              .tooltip-content {
                visibility: visible;
              }
            }

            img {
              width: 15px;
              height: 15px;
              vertical-align: middle;
              margin-left: 6px;
            }
          }
        }
      }

      p {
        margin: 0;
      }

      .condition {
        margin-bottom: 12px;

        p {
          font-weight: 600;
        }
      }

      .button-group {
        bottom: 0;
        display: flex;
        margin-top: 12px;

        & > * {
          flex: 1 2;
        }

        button {
          min-height: 35px;
          border-radius: 8px;
          border: none;

          margin: 0 4px;

          font-size: 0.9rem;
          font-weight: 630;

          &:hover {
            cursor: pointer;
          }

          &.remove {
            background-color: #b5b5b5;
            color: black;
          }

          &.edit {
            background-color: $tint-color;
            color: white;
          }
        }
      }
    }
  }
}