$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.service-plans-detail {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 14px;
  margin-bottom: 24px;

  .page-header {
    margin-bottom: 0px;
  }

  .save {
    outline: none;
    border: none;
    background: none;
    padding: 0;
    color: $tint-color;
    font-weight: 500;
    font-size: 1rem;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  h3 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  p.label {
    font-size: 1.2em;
    font-weight: 600;
  }

  .footnote {
    margin-top: 4px;
    font-size: 0.8em;
    color: #999;
  }

  h3 {
    margin-top: 18px;
  }

  .section {
    > div:not(:last-child) {
      margin-bottom: 16px;
    }

    padding: 16px;
    border-radius: 16px;
    background-color: rgb(235, 238, 238);

    @media (prefers-color-scheme: dark) {
      background-color: #2C2C2E;
    }

    .input {
      display: flex;
      align-items: center;

      .react-datepicker-wrapper {
        width: auto;
        margin-left: auto;
      }
    }
  }

  a.plain {
    &:hover {
      cursor: pointer;
    }
  }
}