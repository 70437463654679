$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.dashboard {
  .header-title {
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: 650;
  }

  .header-subtitle {
    margin-top: 0;
    font-size: 1.7rem;
    font-weight: 600;
  }

  .card-holder { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      flex-direction: column;
    }

    .stat-card { 
      flex: 0 0 20%;
      margin-bottom: 12px;
      margin-right: 12px;

      @include breakpoint(large) {
        flex: 0 0 30%;
      }

      @include breakpoint(medium) {
        flex: 0 0 40%;
      }

      @include breakpoint(small) {
        flex: 0 0 100%;
      }
    }
  }

  .tools-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      flex-direction: column;
    }

    .card {
      position: relative;
      flex: 0 0 20%;
      margin-bottom: 12px;
      margin-right: 12px;

      @include breakpoint(x-large) {
        flex: 0 0 30%;
      }

      @include breakpoint(large) {
        flex: 0 0 40%;
      }

      @include breakpoint(medium) {
        flex: 0 0 100%;
      }
    
      p, h3 {
        margin-top: 0;
        margin-bottom: 0;
      }
    
      p {
        font-weight: 600;
        margin-bottom: 4px;
        margin-right: 36px;
      }
    
      h3 {
        img {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          padding-bottom: 2px;
          margin-right: 2px;
        }

        font-size: 1.3rem;
      }
    
      .linkIcon {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 40%;
        right: 0;
        margin-right: 10px;
      }
    }
  }

  .resource-holder {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;

    @include breakpoint(large) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(medium) {
      grid-template-columns: 1fr;
    }

    iframe {
      aspect-ratio: 16 / 9;
      width: 100%;
    }
  }
}