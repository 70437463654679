$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.sidebar-header {
  position: fixed;
  top: 0;

  background-color: white;

  @media (prefers-color-scheme: dark) {
    background-color: rgb(33, 33, 33);
  }

  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;

  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;

  z-index: 999;

  a {
    margin-left: 18px;
    text-decoration: none;

    img {
      width: 2em;
      height: 2em;

      object-fit: contain;
    }
  }

  h1 {
    margin: 0;
    font-size: 1.75em;
    font-weight: 600;
    color: black;
    margin-left: 16px;

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }
}

.sidebar {
  max-width: 15em;
  min-height: 100vh;
  padding: 0 24px;
  background-color: white;

  @media (prefers-color-scheme: dark) {
    background-color: rgb(33, 33, 33);
  }

  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.1);

  .logo {
    max-width: 65%;
    object-fit: contain;
    margin-top: 8px;
    margin-left: -8px;
  }

  .greeting {
    margin: 0;
    font-weight: 600;
  }

  .churchName {
    margin: 0;
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, 0.5);

    @media (prefers-color-scheme: dark) {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);

    @media (prefers-color-scheme: dark) {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 8px 0;

    li {
      a {
        display: flex;
        text-decoration: none;
        align-items: center;
        margin: 0;

        &:hover {
          text-decoration: none;
        }
      }

      padding: 10px;
      cursor: pointer;

      &.selected {
        background-color: $tint-color;
        border-radius: 8px;

        a {
          color: white;
        }

        img {
          filter: brightness(0) invert(1);
        }

        p {
          font-weight: 550;
        }
      }

      img {
        width: 1.4em;
        height: 1.4em;
        // aspect fit
        object-fit: contain;
        margin-right: 10px;
      }

      p {
        font-size: 1em;
        margin: 0;
        font-weight: 500;
      }
    }
  }
}
