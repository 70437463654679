$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
#sermon-notes-modal {
  background-color: rgb(235, 238, 238);
  overflow: scroll;

  h2 {
    margin-bottom: 0px;
  }
  
  .buttons {
    margin-bottom: 18px;
  }

  p.heading { 
    margin: 0;
    font-weight: 350;
    margin-bottom: 8px;
    margin-left: 4px;
    text-transform: uppercase;
    font-size: .8em;
    color:rgb(113, 113, 118);
  }

  input[type="text"], textarea {
    min-width: 98.5%;
    max-width: 98.5%;
    background: none;
    border: none;
    color: black;

    &:focus {
      outline: none;
    }

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }

  textarea {
    font-family: $font-family;
    min-height: 50px;
    max-height: 250px;
    border-bottom: 1px solid rgb(158, 158, 158);
  }

  label {
    font-size: 0.9em;
    font-weight: 500;
  }

  input[type="checkbox"] {
    float: right;
  }

  .card {
    background-color: white;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 24px;

    @media (prefers-color-scheme: dark) {
      background-color: #2c2c2e;
    }

    a.plain {
      &:hover {
        cursor: pointer;
      }
    }

    .image-with-text-row:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}