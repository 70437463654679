$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.church-manager {
  .info-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;

    @include breakpoint(medium) {
      grid-template-columns: 1fr;
    }

    .group {
      .item {
        margin: 6px 0;

        h1 {
          font-size: 1.3rem;
          margin: 0;
          margin-bottom: 4px;
        }
  
        p, a {
          font-size: 1rem;
          margin: 0;
        }
      }

      .img-item {
        a {
          margin-top: 4px;
          display: block;
        }
      }

      .bold {
        font-weight: 600;
      }

      img.square {
        max-width: 200px;
        max-height: 200px;
        margin-top: 8px;
      }

      img.nav-img {
        max-width: 100%;
        margin-top: 8px;

        filter: brightness(0) saturate(100%);

        @media (prefers-color-scheme: dark) {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(103deg) brightness(105%) contrast(101%) !important;
        }
      }
    }
  }
}