$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.reset-password-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 420px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(small) {
    max-width: 100%;
  }

  .error {
    color: rgb(255, 75, 75);
    font-size: 1rem;
    margin: 10px 32px;
  }

  img {
    margin-top: 24px;
    width: 65%;
    margin-bottom: 4px;
  }

  input {
    width: 85%;
    margin-top: 8px;
    border: none;

    -webkit-appearance: none;
    box-shadow: $card-shadow;
    
    border-radius: 8px;
    min-height: 45px;
    font-size: 1.0rem;
    background-color: white;

    padding: 6px;

    @include breakpoint(small) {
      width: 90%;
    }
  }

  button.reset {
    margin-top: 18px;
    width: 75%;
    border: none;
    border-radius: 35px;
    background-color: $tint-color;
    color: white;
    font-family: $font-family;
    font-weight: 550;
    font-size: 1.3rem;
    min-height: 50px;
    box-shadow: $card-shadow;

    @include breakpoint(small) {
      width: 90%;
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  button.login {
    margin-top: 16px;
    border: none;
    outline: none;
    background: none;

    font-size: 1.15rem;
    font-weight: 550;

    color: $tint-color;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  p {
    font-size: 1.10rem;
    font-weight: 500;
    margin: 16px 0px;
  }
}