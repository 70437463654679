$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.newsletter-manager {
  padding: 0 16px;

  .select-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include breakpoint(medium) {
      grid-template-columns: 1fr;
    }

    div {
      padding-right: 12px;

      label {
        font-size: 14px;
        font-weight: 500;
      }

      select {
        width: 100%;
      }
    }
  }

  .newsletter-container {
    margin-top: 12px;
    margin-bottom: 12px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;

    transition: grid-template-columns 0.2s ease;

    @include breakpoint(x-large) {
      grid-template-columns: 1fr 1fr 1fr;;
    }

    @include breakpoint(large) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(medium) {
      grid-template-columns: 1fr;
    }

    .card {
      h3,
      p {
        margin: 0;
      }

      p {
        margin-bottom: 10px;
      }

      .separator {
        width: 100%;
        height: 1px;
        background-color: rgb(204, 204, 204);
        margin-bottom: 12px;
      }

      a {
        margin-right: 8px;
      }
    }
  }
}