$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.image-with-text-row {
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;

  @media (prefers-color-scheme: dark) {
    color: white;
  }

  p {
    margin: 0;
  }

  img.main {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    object-fit: cover;
  }

  .text {
    display: inline-block;
    margin-left: 12px;

    .title {
      font-weight: 600;
    }
  }

  .indicator {
    width: 16px;
    height: 16px;

    display: inline;
    margin-left: auto;
    text-align: right;
  }
}