$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: right;
  align-items: center;

  height: 90px;
  box-shadow: $card-shadow;
  background-color: white;

  @media (prefers-color-scheme: dark) {
    background-color: #2C2C2E;
  }

  .logo-holder {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    height: 100%;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    * {
      margin-left: 8px;
    }

    img {
      max-height: 90%;
    }

    .church-logo-holder {
      display: flex;

      @include breakpoint(large) {
        display: none;
      }

      .church-logo {
        max-height: 60px;
        max-width: 50%;
        object-fit: contain;
        filter: brightness(0) saturate(100%);

        @media (prefers-color-scheme: dark) {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(103deg) brightness(105%) contrast(101%) !important;
        }
      }
  
      span {
        font-size: 48px;
        margin-bottom: 6px;
        margin-right: 4px;

        @media (prefers-color-scheme: dark) {
          color: white;
        }
      }
    }
  }

  button {
    margin-left: auto;
    margin-right: 12px;
    background-color: $tint-color;
    color: white;
    border: none;
    border-radius: 35px;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: $font-family;
    padding: 10px 16px;

    &:hover {
      cursor: pointer;
    }
  }
}