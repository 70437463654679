$tint-color: #F58B8B;
$card-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.15);
$card-bg-light: white;
$card-bg-dark: #2C2C2E;
$font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text Regular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@mixin breakpoint($point) {
  @if $point == x-large {
    @media screen and (max-width: 1200px) { @content; }
  }

  @if $point == large {
    @media screen and (max-width: 800px) { @content; }
  }

  @if $point == medium {
    @media screen and (max-width: 650px) { @content; }
  }

  @if $point == small {
    @media screen and (max-width: 400px) { @content; }
  }
}

.tinted {
  color: $tint-color;
}

a.plain {
  text-decoration: none;
}

a:not(.plain) {
  color: $tint-color;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.hoverable {
  transition: all 0.2s;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}

.card {
  background-color: $card-bg-light;
  box-shadow: $card-shadow;
  padding: 10px;
  border-radius: 12px;

  @media (prefers-color-scheme: dark) {
    background-color: $card-bg-dark;
  }
}
.team-manager {
  padding: 0 12px;

  .mobile-warning {
    display: none;

    @include breakpoint(small) {
      display: block;
    }
  }

  .card-holder {
    margin: 16px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
    column-gap: 10px;

    @include breakpoint(large) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(medium) {
      grid-template-columns: 1fr;
    }

    .card {
      & > * {
        margin: 0;
      }

      h1 {
        font-size: 1.35rem;
      }

      a {
        font-size: 1rem;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        margin-top: 12px;

        span {
          font-style: italic;
        }
      }

      .divider {
        margin: 8px 0;
        width: 100%;
        height: 1px;
        background-color: rgba(55, 55, 55, 0.1);
      }

      span {
        a {
          margin-right: 8px;

          img {
            width: 16px;
            height: 16px;
            // aspect fit
            object-fit: contain;
            margin-right: 4px;
          }

          span {
            margin-top: 4px;
            font-weight: 550;
          }
        }
      }
    }
  }
}
